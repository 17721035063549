import { type FunctionComponent } from 'react'
import styled from 'styled-components'

/**
 *
 * Props for <CuiFlexGrow>.
 */
type Props = {
  /**
   *
   * Whether to render the text in a `<span>` or a `<div>`
   *
   * @default 'div'
   */
  as?: 'span' | 'div'
}

const CuiFlexGrowRoot = styled.span`
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  flex-grow: 1;
`

/**
 *
 * `<CuiFlexGrow>` is useful for setting up layouts for a single row of content.
 */
export const CuiFlexGrow: FunctionComponent<Props> = ({ as = 'div', children }) => (
  <CuiFlexGrowRoot as={as}>{children}</CuiFlexGrowRoot>
)
