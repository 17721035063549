import classnames from 'classnames'
import { type FunctionComponent } from 'react'
import { type CuiThemeSpacingSizes } from 'src/cui/themes/types'
import styled from 'styled-components'

type CuiSpacerSize = keyof CuiThemeSpacingSizes

/**
 *
 * Props for <CuiSpacer>.
 */
type Props = {
  /**
   *
   * Height of the spacer.
   *
   * @default 'm'
   */
  size?: CuiSpacerSize

  /**
   *
   * Whether to render the spacing as a `<div>` or as a `<hr>` horizontal rule.
   *
   * @default 'div'
   */
  as?: 'div' | 'hr'

  /**
   *
   * This component cannot have `children`.
   */
  children?: never
}

type CuiSpacerRootProps = {
  $size: CuiSpacerSize
}

const CuiSpacerRoot = styled.div<CuiSpacerRootProps>`
  height: ${({ theme, $size }) => theme.cuiSpacingSizes[$size]}px;

  &.CuiSpacerRoot--asHr {
    border: none;
    width: 100%;
    min-height: 1px;
    max-height: 1px;
    background-color: ${({ theme }) => theme.cuiColors.lightShade};
    margin: ${({ theme, $size }) => theme.cuiSpacingSizes[$size]}px 0;
  }
`

/**
 *
 * `<CuiSpacer>` adds an vertical space between items
 */
export const CuiSpacer: FunctionComponent<Props> = ({ size = 'm', as = 'div' }) => (
  <CuiSpacerRoot
    $size={size}
    as={as}
    className={classnames({
      'CuiSpacerRoot--asHr': as === 'hr',
    })}
  />
)
