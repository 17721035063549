// Adapted from https://github.com/elastic/eui/blob/main/src/components/flex/flex_grid.tsx

import { kebabCase } from 'lodash'
import { type FunctionComponent } from 'react'
import { useIsMobile } from 'src/utils/mediaQuery'
import styled from 'styled-components'

import { type CuiThemeSpacingSizes } from '../../themes/types'

export type CuiFlexGridAlignItems = 'stretch' | 'flexStart' | 'flexEnd' | 'center' | 'baseline'

export type CuiFlexGridGutterSize = keyof CuiThemeSpacingSizes

/**
 *
 * Props for <CuiFlexGrid>.
 */
type Props = {
  /**
   *
   * How to align the contents of the grid.
   *
   * @default 'stretch'
   */
  alignItems?: CuiFlexGridAlignItems

  /**
   *
   * Margin between items
   *
   * @default 'm'
   */
  gutterSize?: CuiFlexGridGutterSize

  /**
   *
   * Number of columns in the grid
   *
   * @default 1
   */
  columns?: number

  /**
   *
   *  Will display each item at full-width on smaller screens
   *
   * @default true
   */
  responsive?: boolean

  /**
   *
   * Whether to render the text in a `<span>` or a `<div>`
   *
   * @default 'div'
   */
  as?: 'span' | 'div'
}

type CuiFlexGridRootProps = {
  $alignItems: CuiFlexGridAlignItems
  $gutterSize: CuiFlexGridGutterSize
  $columns: number
}

const CuiFlexGridRoot = styled.span<CuiFlexGridRootProps>`
  display: grid;
  grid-template-columns: repeat(${({ $columns }) => $columns}, 1fr);

  gap: ${({ theme, $gutterSize }) => theme.cuiSpacingSizes[$gutterSize]}px;

  align-items: ${({ $alignItems }) => kebabCase($alignItems)};
`

/**
 *
 * `<CuiFlexGrid>`
 */
export const CuiFlexGrid: FunctionComponent<Props> = ({
  alignItems = 'stretch',
  gutterSize = 'm',
  columns: consumerColumns = 1,
  responsive = true,
  as = 'div',
  children,
}) => {
  const isMobile = useIsMobile()

  const columns = isMobile && responsive ? 1 : consumerColumns

  return (
    <CuiFlexGridRoot $alignItems={alignItems} $gutterSize={gutterSize} $columns={columns} as={as}>
      {children}
    </CuiFlexGridRoot>
  )
}
