import * as RadixTooltip from '@radix-ui/react-tooltip'
import { type FunctionComponent } from 'react'
import styled from 'styled-components'

import { CuiText } from '../CuiText'

type CuiTooltipSide = 'top' | 'right' | 'bottom' | 'left'
type CuiTooltipAlign = 'start' | 'center' | 'end'

/**
 *
 * Props for <CuiTooltip>.
 */
type Props = {
  /**
   *
   * The content within the tooltip
   */
  content: string

  /**
   *
   * Preferred side of the trigger to render against
   *
   * @default 'bottom'
   */
  side?: CuiTooltipSide

  /**
   *
   * Preferred alignment against the trigger
   *
   * @default 'center'
   */
  align?: CuiTooltipAlign
}

const TOOLTIP_DELAY_DURATION = 500

const CuiTooltipArrow = styled(RadixTooltip.Arrow)`
  fill: ${({ theme }) => theme.cuiColors.darkestShade};
`

const CuiTooltipContentRoot = styled(RadixTooltip.Content)`
  border-radius: 4px;
  padding: 6px 10px;
  background-color: ${({ theme }) => theme.cuiColors.darkestShade};
  display: inline-flex;
  align-items: center;
`

export const CuiTooltip: FunctionComponent<Props> = ({
  content,
  children,
  side = 'bottom',
  align = 'center',
}) => {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={TOOLTIP_DELAY_DURATION}>
        <RadixTooltip.Trigger asChild={true}>
          <span>{children}</span>
        </RadixTooltip.Trigger>
        <CuiTooltipContentRoot side={side} align={align}>
          <CuiText size='caption' color='inverseText'>
            {content}
          </CuiText>
          <CuiTooltipArrow offset={4} />
        </CuiTooltipContentRoot>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}
