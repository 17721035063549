// Adapted from https://glennmccomb.com/articles/building-a-pure-css-animated-svg-spinner/

import { type FunctionComponent } from 'react'
import { type CuiThemeColor } from 'src/cui/themes/types'
import styled from 'styled-components'

type StyledSvgProps = { $width: string }

const StyledSvg = styled.svg<StyledSvgProps>`
  animation: 2s linear infinite svg-animation;
  max-width: ${({ $width }) => $width};

  @keyframes svg-animation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`

type StyledCircleProps = { $color: CuiThemeColor }

const StyledCircle = styled.circle<StyledCircleProps>`
  animation: 1.4s ease-in-out infinite both circle-animation;
  display: block;
  fill: transparent;
  stroke: ${({ theme, $color }) => theme.cuiColors[$color]};
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-width: 10px;
  transform-origin: 50% 50%;

  @keyframes circle-animation {
    0%,
    25% {
      stroke-dashoffset: 280;
      transform: rotate(0);
    }

    50%,
    75% {
      stroke-dashoffset: 75;
      transform: rotate(45deg);
    }

    100% {
      stroke-dashoffset: 280;
      transform: rotate(360deg);
    }
  }
`

type CuiLoadingSpinnerColor = 'text' | 'accent'

type ColorMap = { [color in CuiLoadingSpinnerColor]: CuiThemeColor }

const colorMap: ColorMap = {
  text: 'text',
  accent: 'accent',
}

type CuiLoadingSpinnerSize = 's' | 'm' | 'l' | 'xl'

type SizeMap = { [color in CuiLoadingSpinnerSize]: string }

const sizeMap: SizeMap = {
  s: '25px',
  m: '50px',
  l: '100px',
  xl: '200px',
}

type Props = {
  /**
   *
   * Color of the spinner.
   *
   * @default 'accent'
   */
  color?: CuiLoadingSpinnerColor

  /**
   *
   * Size of the spinner.
   *
   * @default 'm'
   */
  size?: CuiLoadingSpinnerSize
}

export const CuiLoadingSpinner: FunctionComponent<Props> = ({ color = 'accent', size = 'm' }) => {
  return (
    <StyledSvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' $width={sizeMap[size]}>
      <StyledCircle $color={colorMap[color]} cx='50' cy='50' r='45' />
    </StyledSvg>
  )
}
