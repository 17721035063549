import * as RadixHoverCard from '@radix-ui/react-hover-card'
import { type FunctionComponent, type ReactNode } from 'react'
import styled from 'styled-components'

import { type CuiIconType, CuiIcon } from '../CuiIcon'

type CuiIconPopoverSide = 'top' | 'right' | 'bottom' | 'left'
type CuiIconPopoverAlign = 'start' | 'center' | 'end'

type Props = {
  /**
   *
   * Contents of the popover
   */
  content: ReactNode

  /**
   *
   * The icon type.
   */
  iconType: CuiIconType

  /**
   *
   * Preferred side of the trigger to render against when open.
   * Reversed when collisions occur
   *
   * @default 'bottom'
   */
  side?: CuiIconPopoverSide

  /**
   *
   * Preferred alignment against the trigger
   *
   * @default 'center'
   */
  align?: CuiIconPopoverAlign
}

const CuiIconPopoverRoot = styled(RadixHoverCard.Content)`
  border-radius: 8px;
  padding: 16px;
  background-color: ${({ theme }) => theme.cuiColors.lightestShade};
  box-shadow: 0px 1.5px 12px rgba(0, 0, 0, 0.2);
  max-width: 250px;
`

const CuiIconPopoverTrigger = styled(RadixHoverCard.Trigger)`
  all: unset;
  display: inline-flex;

  &:hover {
    background-color: ${({ theme }) => theme.cuiColors.overlayHover01};
    border-radius: 2px;
  }
`

export const CuiIconPopover: FunctionComponent<Props> = ({
  content,
  iconType,
  side = 'bottom',
  align = 'center',
}) => {
  return (
    <RadixHoverCard.Root openDelay={0} closeDelay={0}>
      <CuiIconPopoverTrigger>
        <CuiIcon type={iconType} />
      </CuiIconPopoverTrigger>
      <CuiIconPopoverRoot side={side} align={align} sideOffset={8}>
        {content}
      </CuiIconPopoverRoot>
    </RadixHoverCard.Root>
  )
}
