import classnames from 'classnames'
import { type ChangeEvent, type ForwardedRef, type HTMLAttributes, forwardRef } from 'react'
import styled from 'styled-components'

import { type CuiIconType, CuiIcon } from '../CuiIcon'
import { mixText } from '../CuiText'

type CuiInputIconSide = 'left' | 'right'

/**
 *
 * Props for <CuiInput>.
 */
type Props = Partial<Pick<HTMLAttributes<HTMLInputElement>, 'onKeyDown'>> & {
  /**
   *
   * The state of the input
   */
  value: string

  /**
   *
   * The placeholder text
   */
  placeholder?: string

  /**
   *
   * When `true`, prevents the user from interacting with the input
   *
   * @default false
   */
  disabled?: boolean

  /**
   *
   * The icon type.
   */
  iconType?: CuiIconType

  /**
   *
   * The side the icon is on.
   *
   * @default 'left'
   */
  iconSide?: CuiInputIconSide

  /**
   *
   * If the input box has a border when focused or non-focused
   *
   * @default true
   */
  border?: boolean

  /**
   *
   * The function called when the input changes
   */
  onChange: (value: string) => void

  /**
   *
   * Internal. Consuming _nativeOnChange externally is discouraged.
   * The function called when the input changes
   */
  _nativeOnChange?: HTMLAttributes<HTMLInputElement>['onChange']
}

type CuiInputRootProps = {
  $iconSide: CuiInputIconSide
  $border: boolean
}

const CuiInputRoot = styled.input<CuiInputRootProps>`
  background: none;

  padding: 8px 16px;

  ${mixText({ $color: 'text', $size: 'paragraph1', $fontWeight: 'regular' })}

  border-radius: 4px;

  border-width: ${({ $border }) => ($border ? '1px' : '0px')};
  border-style: solid;
  border-color: ${({ theme }) => theme.cuiColors.darkerShade};
  display: block;

  :focus {
    border-color: ${({ theme }) => theme.cuiColors.darkestShade};
    outline-width: ${({ $border }) => ($border ? '1px' : '0px')};
    outline-style: solid;
    outline-color: ${({ theme }) => theme.cuiColors.darkestShade};
  }

  ::placeholder {
    color: ${({ theme }) => theme.cuiColors.lightestText};
  }

  :disabled {
    color: ${({ theme }) => theme.cuiColors.disabled02};
    border-color: ${({ theme }) => theme.cuiColors.disabled02};
    cursor: not-allowed;

    ::placeholder {
      color: ${({ theme }) => theme.cuiColors.disabled02};
    }
  }

  &.CuiInputRoot--hasIcon {
   padding-${({ $iconSide }) => $iconSide}: 32px;
  }

  width: 100%;
`

const CuiInputWrapper = styled.div`
  position: relative;

  &.CuiInputWrapper--disabled {
    cursor: not-allowed;
  }
`

type CuiInputIconWrapperProps = {
  $iconSide: CuiInputIconSide
}

const CuiInputIconWrapper = styled.div<CuiInputIconWrapperProps>`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;

  ${({ $iconSide }) => $iconSide}: 8px;

  color: ${({ theme }) => theme.cuiColors.lighterText};

  &.CuiInputIconWrapper--disabled {
    color: ${({ theme }) => theme.cuiColors.disabled02};
  }

  pointer-events: none;
`

/**
 *
 * <CuiInput>
 */

function CuiInputImpl(
  {
    onChange: consumerOnChange,
    iconType,
    disabled,
    iconSide = 'left',
    _nativeOnChange,
    border = true,
    ...restProps
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <CuiInputWrapper
      className={classnames({
        'CuiInputWrapper--disabled': disabled,
      })}
    >
      <CuiInputIconWrapper
        $iconSide={iconSide}
        className={classnames({
          'CuiInputIconWrapper--disabled': disabled,
        })}
      >
        <CuiIcon type={iconType} />
      </CuiInputIconWrapper>

      <CuiInputRoot
        ref={ref}
        {...restProps}
        $iconSide={iconSide}
        $border={border}
        disabled={disabled}
        className={classnames({
          'CuiInputRoot--hasIcon': iconType !== undefined,
        })}
        onChange={onChange}
      />
    </CuiInputWrapper>
  )

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    _nativeOnChange?.(event)
    consumerOnChange(event.target.value)
  }
}

export const CuiInput = forwardRef<HTMLInputElement, Props>(CuiInputImpl)
